@media print {
    .MuiAppBar-root, .no-print {
      display: none !important;
    }
    .printable-page {
        border: none;
        margin-bottom: 0 !important;
        -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
        color-adjust: exact !important;                 /* Firefox 48 – 96 */
        print-color-adjust: exact !important;    /* Firefox 97+, Safari 15.4+ */
    }
    .printable-page > .MuiPaper-root{
        box-shadow: none !important;
    }
    .unbreakable{
        break-inside: avoid !important;
    }
    .MuiBox-root {
        margin-top: 0 !important;
    }
  }