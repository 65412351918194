@media print {
  .MuiAppBar-root, .no-print {
    display: none !important;
  }

  .printable-page {
    border: none;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
    margin-bottom: 0 !important;
  }

  .printable-page > .MuiPaper-root {
    box-shadow: none !important;
  }

  .unbreakable {
    break-inside: avoid !important;
  }

  .MuiBox-root {
    margin-top: 0 !important;
  }
}
/*# sourceMappingURL=index.41e7e099.css.map */
